<template>
  <b-input-group>
    <template #prepend>
      <b-input-group-text
        v-if="label"
        :style="`width: ${labelWidth || 'fit-content'}`"
      >
        {{ label }}
      </b-input-group-text>
    </template>
    <b-form-input
      v-if="type === 'input'"
      :id="id"
      v-model="formValue.value"
      :disabled="disabled"
      :placeholder="placeholder"
      :class="{ 'input-without-label': !label, 'highlight': formValue.isValueChanged }"
      @keydown="onKeydown"
      @blur="$emit('blur', $event)"
    />
    <HeroVueSelect
      v-if="type === 'select'"
      :id="id"
      v-model="formValue.value"
      :options="options"
      :disabled="disabled"
      :placeholder="placeholder"
      :clearable="false"
      :class="['custom-vue-select', { 'custom-vue-select-with-group': label }, { 'highlight': formValue.isValueChanged }]"
      @input="$emit('select', $event)"
    />
    <b-input-group-append v-if="type === 'input'">
      <b-input-group-text
        v-if="limit"
        :class="['character-count', { 'disabled': disabled, 'highlight': formValue.isValueChanged }]"
      >
        {{ formValue.value.length }}/{{ limit }}
      </b-input-group-text>
      <b-dropdown
        v-if="pinPositions.length"
        :text="formValue.pin ? `${formValue.pin}` : '-'"
        :class="['dropdown-pin', { 'highlight': formValue.isPinChanged }]"
        variant="outline-primary"
        :disabled="disabled"
        right
      >
        <template v-for="pinPosition in [0, ...pinPositions]">
          <b-dropdown-item
            :key="pinPosition"
            :active="formValue.pin === pinPosition"
            @click="formValue.pin = pinPosition; $emit('pin-change')"
          >
            {{ pinPosition === 0 ? '-' : `Pin Position ${pinPosition}` }}
          </b-dropdown-item>
        </template>
      </b-dropdown>
      <b-button
        class="btn-copy"
        variant="outline-primary"
        @click="handleCopy"
      >
        <feather-icon
          icon="CopyIcon"
          size="20"
        />
      </b-button>
      <b-button
        v-if="removable"
        class="btn-remove"
        variant="danger"
        @click="$emit('remove-item')"
      >
        <feather-icon
          icon="XIcon"
          size="20"
        />
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'

export default {
  name: 'GoogleAdsMockupInput',
  components: { HeroVueSelect },
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({
        value: '',
        pin: 0,
        isValueChanged: false,
        isPinChanged: false,
      }),
    },
    type: {
      type: String,
      default: 'input',
    },
    options: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: 'Label',
    },
    labelWidth: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    pinPositions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCopied: false,
    }
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    handleCopy() {
      this.isCopied = true
      navigator.clipboard.writeText(this.value.value)
      setTimeout(() => {
        this.isCopied = false
      }, 1000)
    },

    onKeydown(e) {
      const { value } = e.target
      const isDeleteContentBackward = e.which === 8
      const isDeleteContentForward = e.which === 46

      if (!isDeleteContentBackward && !isDeleteContentForward && this.limit > 0 && this.limit <= value.length) {
        e.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/variables';
.input-group {
  .input-group-prepend {
    .input-group-text {
      background: $primary;
      color: white;
      justify-content: center;
    }
  }

  .form-control {
    padding-left: 12px;
    padding-right: 12px;
    border-right: 0;
    &.input-without-label {
      border-top-left-radius: 0.357rem;
      border-bottom-left-radius: 0.357rem;
    }
    &.highlight {
      color: #1C9D2D;
      background-color: #C3EFD7;
    }
  }

  .input-group-append {
    .character-count {
      font-size: 10px;
      border-left: unset;
      padding: 3px;
      &.disabled {
        background-color: #EFEFEF;
      }
      &.highlight {
        background-color: #C3EFD7;
      }
    }
    .dropdown-pin {
      ::v-deep.btn {
        padding: 0;
        width: 60px;
        border-right: 0 !important;
        &[disabled=disabled] {
          background-color: #EFEFEF;
        }
      }
      &.highlight {
        ::v-deep.btn {
          color: #1C9D2D;
          background-color: #C3EFD7;
        }
      }
    }
    .btn-copy, .btn-remove {
      padding: 0;
      width: 37.99px;
    }
  }
}

.custom-vue-select {
  flex: 1 0 0%;
  margin-bottom: 0;
  &.custom-vue-select-with-group {
    ::v-deep.vs__dropdown-toggle {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.highlight {
    background-color: #C3EFD7;
    ::v-deep.vs__selected {
      color: #1C9D2D;
    }
  }
}
</style>
