<template>
  <b-modal
    id="confirm-google-ads-mockup-form-modal"
    ref="confirm-google-ads-mockup-form-modal"
    title="Confirmation Form"
    centered
    hide-footer
    no-close-on-backdrop
    @shown="onShown"
    @hidden="resetForm"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.prevent="handleConfirm">
        <validation-provider
          #default="{ errors }"
          name="name"
          rules="required"
        >
          <HeroInputText
            id="name"
            v-model="form.name"
            label="Mockup Name"
            required
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="editor name"
          rules="required"
        >
          <HeroInputText
            id="editor-name"
            v-model="form.editorName"
            label="Editor Name"
            required
            :disabled="isAuthenticated"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            @focusout="form.editorName = isAuthenticated ? $store.getters['heroAiAuthentications/fullName'] : form.editorName"
          />
        </validation-provider>
        <HeroTextarea
          id="remark"
          v-model="form.remark"
          label="Remark"
          rows="5"
          max-rows="10"
        />
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction
              type="submit"
              variant="primary"
            >
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="outline-secondary"
              @click="$bvModal.hide('confirm-google-ads-mockup-form-modal')"
            >
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>

import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'

export default {
  components: {
    HeroButtonAction,
    HeroTextarea,
    HeroInputText,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    remark: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showOverlay: false,
      form: {
        name: '',
        editorName: '',
        remark: '',
      },
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['heroAiAuthentications/isAuthenticated']
    },
  },
  methods: {
    onShown() {
      this.form.name = this.name
      this.form.editorName = this.$store.getters['heroAiAuthentications/fullName'] || ''
      this.form.remark = this.remark
    },

    async handleConfirm() {
      const isFormValid = await this.$refs.observer.validate()

      if (isFormValid) {
        this.$emit('confirm', this.form)
        this.$bvModal.hide('confirm-google-ads-mockup-form-modal')
      }
    },

    resetForm() {
      this.form = {
        name: '',
        editorName: '',
        remark: '',
      }
    },
  },
}
</script>
